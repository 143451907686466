import React from "react";
import Layout from "../components/layout";
import AreaLayoutImg from "../assets/images/area-layouts/nlrrwa-layout-2024.jpg";
import "../styles/pages/area-layout.scss";

export default function AreaLayout() {
  return (
    <Layout title="Area Layout">
      <h1 className="page-title">Area Layout</h1>
      <img
        className="area-layout"
        src={AreaLayoutImg}
        alt="NLRRWA Area Layout"
      />
    </Layout>
  );
}
